$(document).ready(function () {
    var initMiewSlider = function () {
        if ($('.banners').length > 0) {//Banners Homepage        
            if ($('.slick-banners').length > 0) {

                var $dots = false,
                    $arrows = false,
                    $dots_mobile = false,
                    $arrows_mobile = false,
                    $cursors_mobile = false;

                if ($('.banners').hasClass('has_dots')) $dots = true;
                if ($('.banners').hasClass('has_arrows')) $arrows = true;
                if ($('.banners').hasClass('has_dots_mobile')) $dots_mobile = true;
                if ($('.banners').hasClass('has_arrows_mobile')) $arrows_mobile = true;
                if ($('.banners').hasClass('has_cursors_mobile')) $cursors_mobile = true;

                $('.slick-banners').on('init', function (event, slick, currentSlide, nextSlide) {
                    var bgss = new bgsrcset();
                    bgss.callonce = false;
                    bgss.init('.banners_img');
                });
                $('.slick-banners').slick({
                    dots: $dots,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: $arrows,
                    infinite: false,
                    adaptiveHeight: false,
                    fade: true,
                    autoplay: true,
                    autoplaySpeed: 8000,
                    cssEase: 'linear',
                    responsive: [
                        {
                            breakpoint: 960,
                            settings: {
                                dots: $dots_mobile,
                                arrows: $arrows_mobile,
                                cursors: $cursors_mobile,
                            }
                        }
                    ]
                });
            }
        }
    }

    initMiewSlider();
});