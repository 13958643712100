function sliderCursor() {
    var owl = $('.owl-carousel');

    owl.each(function () {
        var owl_element = $(this);

        if (!owl_element.hasClass('olw-cursors')) {
            owl_element.addClass('olw-cursors');

            $('<a class="gallery-cursor cursor-prev" aria-label="Previous" href="javascript:;"></a>').prependTo(owl_element.parents('.owl-carousel-wrapper'));
            $('<a class="gallery-cursor cursor-next" aria-label="Next" href="javascript:;"></a>').appendTo(owl_element.parents('.owl-carousel-wrapper'));

            owl_element.owlCarousel();

            // disable cursors
            owl_element.on('initialized.owl.carousel changed.owl.carousel refreshed.owl.carousel', function (event) {
                if (!event.namespace) return;
                var carousel = event.relatedTarget,
                    element = event.target,
                    current = carousel.current();

                $('.gallery-cursor.cursor-next', element).toggleClass('disabled', current === carousel.maximum());
                $('.gallery-cursor.cursor-prev', element).toggleClass('disabled', current === carousel.minimum());
            });
            // Go to the next item
            $('.gallery-cursor.cursor-prev').on('click', function () {
                owl_element.trigger('prev.owl.carousel');
            });
            // Go to the previous item
            $('.gallery-cursor.cursor-next').on('click', function () {
                owl_element.trigger('next.owl.carousel');
            });
        }
    });
}


$(document).ready(function () {
    /*HELPERS*/
    var templateUrl = phpVars.stylesheet_directory_uri;
    
    if ($('.intro_div ').length>0){
        $(window).on('scroll', function(){
            var offset = $('.intro_div').height() - ($('#masthead > div').outerHeight() / 2);        

            if ($(this).scrollTop() >= offset){
                $('#masthead').addClass('dark-mode');
            }else{
                if ($('#masthead').hasClass('dark-mode')) $('#masthead').removeClass('dark-mode');
            }
        });
    }else{
        $('#masthead').addClass('dark-mode');
    }
    
    $(window).resized(function () {
        var alt_menu = $('#masthead > div').outerHeight();
        $('.menu-wrapper').height(alt_menu);
    });
});
function play_video(){
    $('.intro_div .to_hide').fadeOut('fast');
    $('.mejs-overlay-play').trigger('click');

}

/***************** FUNCTIONS *****************/
function initScrollIndicator() {    
    if ($('#progress-bar').length==0) $('<div id="progress-bar"></div>').appendTo('body');
    var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    document.getElementById("progress-bar").style.width = scrolled + "%";
}
function goTo(obj, center) {
    'use strict';

    if ($(obj).length > 0) {
        if (center == 1) {
            var elOffset = $(obj).offset().top;
            var elHeight = $(obj).height();
            var windowHeight = $(window).height();
            var offset;

            var cenas = (windowHeight / 2);
            var cenas2 = (elHeight / 2);

            if (elHeight < windowHeight) {
                offset = elOffset - (cenas - cenas2);
            }

            $('html, body').stop(true, true).animate({
                scrollTop: offset
            }, 1500, '', function () { });
        } else {
            var offset = $(obj).offset().top;
            $('html, body').stop(true, true).animate({
                scrollTop: offset
            }, 1500, '', function () { });
        }
    }
}
function debounce(fn, threshold) {
    var timeout;
    threshold = threshold || 100;
    return function debounced() {
        clearTimeout(timeout);
        var args = arguments;
        var _this = this;
        function delayed() {
            fn.apply(_this, args);
        }
        timeout = setTimeout(delayed, threshold);
    };
}
function init_shares() {
    'use strict';

    if ($('.share-icon').length > 0) {
        $('.share-icon').click(function () {
            if ($(this).attr('data-link')) {
                window.open($(this).attr('data-link'), 'targetWindow', 'toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=no, width=500 ,height=400');
            }
        });
    }
}
function elementParallax() {
    // Homepage next event
    $('.animated-parallax').each(function (index, element) {
        var controller = new ScrollMagic.Controller();

        var neChild = $(this).find('.parallax-el'),
            data_y = neChild.attr('data-y'),
            data_invert = neChild.attr('data-invert');

        if (data_invert == 1) {
            var tween = new TimelineMax().add([
                TweenMax.fromTo(neChild, 1, { y: -data_y }, { y: data_y, ease: Linear.easeNone }),
            ]);
        } else {
            var tween = new TimelineMax().add([
                TweenMax.fromTo(neChild, 1, { y: data_y }, { y: -data_y, ease: Linear.easeNone }),
            ]);
        }

        var scene = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 1,
            duration: '200%'
        }).setTween(tween).addTo(controller);
    });
}



function init_fades() {
    'use strict';
    //Elements Fading
    if ($('.elements_animated').length > 0) {
        $('.elements_animated').each(function (index, element) {
            var watcher = scrollMonitor.create(element);

            watcher.enterViewport(function () {
                $(element).addClass('active');

                setTimeout(function () {
                    $(element).removeClass('elements_animated active');
                }, 1000);

                watcher.destroy();
            });
        });
    }
}

function init_inputs() {
    'use strict';

    if ($('.field_holder').length > 0) {
        $('.field_holder').each(function (index, element) {
            $("input, textarea", element).on("keypress focus", function () {
                if ($(this).val() !== '') {
                    $(this).parents('.field_holder').addClass('focused');
                } else {
                    $(this).parents('.field_holder').removeClass('focused');
                }
            });
            $("input, textarea", element).on("blur", function () {
                if ($(this).val() !== '') {
                    $(this).addClass('focused filled');
                } else {
                    $(this).removeClass('focused filled');
                }
            });
        });
    }
}

function init_appear(){
    var appearedElements = '.home_svg, .button-big, .svg_arrow, .parceiros_grid, span[style^="text-decoration: underline"], span[style *= " text-decoration: underline"], .uptec_mission';

    if ($(appearedElements).length > 0) {
        $(appearedElements).each(function (index, element) {
            var watcher = scrollMonitor.create(element);

            if ($(element).height() > $(window).height()) {
                watcher.enterViewport(function () {
                    $(element).addClass('appeared');
                    watcher.destroy();
                });
            } else {
                watcher.fullyEnterViewport(function () {
                    $(element).addClass('appeared');
                    watcher.destroy();
                });
            }
        });
    }
}

/*-------------------------------------------------------------------------------------------
=HANDLE MENU EFFECT - RUI
--------------------------------------------------------------------------------------------*/
function initMenuMobile() {
    if ($('nav#menu').length > 0) {
        $('.mobile-menu-button').on('click', function () {
            triggerMobileMenu();
        });
        
        $('body').on('click', function (e) {
            'use strict';
            if ($(e.target).closest('.mobile-menu-button').length == 0 && $(e.target).closest('nav#menu').length == 0) {
                if ($('nav#menu').hasClass('menu-opened')) triggerMobileMenu();
            }
        });
    }
}

function triggerMobileMenu() {
    if ($('nav#menu').length > 0) {
        $('body').toggleClass('overHidden-menu');
        $('#main-logo svg').toggleClass('dark');

        if (!$('body').hasClass('off-opened')) {
            var animation = new TimelineMax({
                onComplete: function () {
                    setTimeout(function () {
                        $('body').toggleClass('menu-opened');
                        TweenMax.set(".menu_lines.dark span", { scaleX: 0, clearProps: 'all' });
                    }, 600);
                }
            });

            setTimeout(function () {
                TweenMax.to(".post-body", 1, { 
                    opacity: 1 
                });
            }, 300);

            animation.staggerFromTo(document.querySelectorAll(".menu_lines.dark span"), 1.2, {
                scaleX: 0
            }, {
                scaleX: 1,
                delay: .2,
                ease: Power2.easeOut
            }, .02);
        }else{
            var animation = new TimelineMax({
                onComplete: function () {
                    $('body').toggleClass('menu-opened');

                    setTimeout(function () {
                        TweenMax.set(".menu_lines.light span", { scaleX: 0, clearProps: 'all' });
                    }, 200);
                }
            });
            
            animation.staggerFromTo(document.querySelectorAll(".menu_lines.light span"), 1.2, {
                scaleX: 0
            }, {
                scaleX: 1,
                delay: .2,
                ease: Power2.easeOut
            }, .02);
        }        
    }
}   

function getRandom(min, max){
    return (Math.random() * (max - min) + min).toFixed(2);
}

/***************** PLUGINS *****************/
(function ($) { //$(window).scrolled(function() {	 Utilizar em vez de window on scroll, corre o codigo apenas quando o utilizar para o scroll
    'use strict';
    var uniqueCntr = 0;
    $.fn.scrolled = function (waitTime, fn) {
        if (typeof waitTime === "function") {
            fn = waitTime;
            waitTime = 200;
        }
        var tag = "scrollTimer" + uniqueCntr++;
        this.scroll(function () {
            var self = $(this);
            var timer = self.data(tag);
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(function () {
                self.removeData(tag);
                fn.call(self[0]);
            }, waitTime);
            self.data(tag, timer);
        });
    };
})(jQuery);

(function ($) { //$(window).resized(function() {	 Utilizar em vez de window on resize, corre o codigo apenas quando o utilizar para o resize
    'use strict';
    var uniqueCntr = 0;
    $.fn.resized = function (waitTime, fn) {
        if (typeof waitTime === "function") {
            fn = waitTime;
            waitTime = 200;
        }
        var tag = "resizeTimer" + uniqueCntr++;
        this.resize(function () {
            var self = $(this);
            var timer = self.data(tag);
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(function () {
                self.removeData(tag);
                fn.call(self[0]);
            }, waitTime);
            self.data(tag, timer);
        });
    };
})(jQuery);


/***************** INITS *****************/
$(document).ready(function(){

});
document.addEventListener('DOMContentLoaded', function () {
    var delay = $('.loader .loading').length>0 ? 1500 : 250;
    
    setTimeout(function () {
        $('.page-wrapper').imagesLoaded({ background: true }, function () {
            $('.loader').fadeOut('fast', function () {
                initMenuMobile();
                init_inputs();
                init_shares();
                
                if ($('.home_intro').length == 0) {
                    initScripts();
                }
            });
        });
    }, delay);    
});

function initScripts(){
    $('body').addClass('doc-ready');
    init_appear();
    init_fades();

    elementParallax();

    window.dispatchEvent(new Event('resize'));

    $(window).scroll(function () {
        initScrollIndicator();
    });
}
$(document).ready(function () {
    var initMiewSlider = function () {
        if ($('.banners').length > 0) {//Banners Homepage        
            if ($('.slick-banners').length > 0) {

                var $dots = false,
                    $arrows = false,
                    $dots_mobile = false,
                    $arrows_mobile = false,
                    $cursors_mobile = false;

                if ($('.banners').hasClass('has_dots')) $dots = true;
                if ($('.banners').hasClass('has_arrows')) $arrows = true;
                if ($('.banners').hasClass('has_dots_mobile')) $dots_mobile = true;
                if ($('.banners').hasClass('has_arrows_mobile')) $arrows_mobile = true;
                if ($('.banners').hasClass('has_cursors_mobile')) $cursors_mobile = true;

                $('.slick-banners').on('init', function (event, slick, currentSlide, nextSlide) {
                    var bgss = new bgsrcset();
                    bgss.callonce = false;
                    bgss.init('.banners_img');
                });
                $('.slick-banners').slick({
                    dots: $dots,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: $arrows,
                    infinite: false,
                    adaptiveHeight: false,
                    fade: true,
                    autoplay: true,
                    autoplaySpeed: 8000,
                    cssEase: 'linear',
                    responsive: [
                        {
                            breakpoint: 960,
                            settings: {
                                dots: $dots_mobile,
                                arrows: $arrows_mobile,
                                cursors: $cursors_mobile,
                            }
                        }
                    ]
                });
            }
        }
    }

    initMiewSlider();
});