function sliderCursor() {
    var owl = $('.owl-carousel');

    owl.each(function () {
        var owl_element = $(this);

        if (!owl_element.hasClass('olw-cursors')) {
            owl_element.addClass('olw-cursors');

            $('<a class="gallery-cursor cursor-prev" aria-label="Previous" href="javascript:;"></a>').prependTo(owl_element.parents('.owl-carousel-wrapper'));
            $('<a class="gallery-cursor cursor-next" aria-label="Next" href="javascript:;"></a>').appendTo(owl_element.parents('.owl-carousel-wrapper'));

            owl_element.owlCarousel();

            // disable cursors
            owl_element.on('initialized.owl.carousel changed.owl.carousel refreshed.owl.carousel', function (event) {
                if (!event.namespace) return;
                var carousel = event.relatedTarget,
                    element = event.target,
                    current = carousel.current();

                $('.gallery-cursor.cursor-next', element).toggleClass('disabled', current === carousel.maximum());
                $('.gallery-cursor.cursor-prev', element).toggleClass('disabled', current === carousel.minimum());
            });
            // Go to the next item
            $('.gallery-cursor.cursor-prev').on('click', function () {
                owl_element.trigger('prev.owl.carousel');
            });
            // Go to the previous item
            $('.gallery-cursor.cursor-next').on('click', function () {
                owl_element.trigger('next.owl.carousel');
            });
        }
    });
}


$(document).ready(function () {
    /*HELPERS*/
    var templateUrl = phpVars.stylesheet_directory_uri;
    
    if ($('.intro_div ').length>0){
        $(window).on('scroll', function(){
            var offset = $('.intro_div').height() - ($('#masthead > div').outerHeight() / 2);        

            if ($(this).scrollTop() >= offset){
                $('#masthead').addClass('dark-mode');
            }else{
                if ($('#masthead').hasClass('dark-mode')) $('#masthead').removeClass('dark-mode');
            }
        });
    }else{
        $('#masthead').addClass('dark-mode');
    }
    
    $(window).resized(function () {
        var alt_menu = $('#masthead > div').outerHeight();
        $('.menu-wrapper').height(alt_menu);
    });
});
function play_video(){
    $('.intro_div .to_hide').fadeOut('fast');
    $('.mejs-overlay-play').trigger('click');

}
